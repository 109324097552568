import React, { useState } from 'react'
import { StyledFormControl, StyledFormLabel, style } from './StyledContactForm'
import Form from 'react-bootstrap/Form'

function TextField({ field, i, onValidationChange }) {
  const [value, setValue] = useState('')

  const handleChange = (event) => {
    setValue(event.target.value)
    onValidationChange(event.target.value !== '') // Gültigkeit prüfen und Callback aufrufen
  }
  return (
    <Form.Group key={i}>
      <StyledFormLabel htmlFor={field.field_name}>
        {field.field_name} {field.required === true ? ' *' : null}
      </StyledFormLabel>
      <StyledFormControl
        name={field.field_name}
        required={field.required === true}
        type={field.field_type}
        id={field.field_name}
        value={value} // value Prop hinzufügen
        onChange={handleChange} // onChange Prop hinzufügen
      />
      <Form.Control.Feedback type="invalid">
        {field.invalid_feedback_text}
      </Form.Control.Feedback>
    </Form.Group>
  )
}

export default TextField
