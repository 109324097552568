import React from 'react'
import Form from 'react-bootstrap/Form'
function Switch({ field }) {
  return (
    <Form.Check // prettier-ignore
      name={field.field_name}
      type={field.field_type}
      id={field.field_name}
      label={field.field_name}
      required={field.required === true}
    />
  )
}

export default Switch