import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import { StyledFormControl, StyledFormLabel, style } from './StyledContactForm'

const CheckRadio = ({ field, onValidationChange }) => {
const [value, setValue] = useState('')

const handleChange = (event) => {
  setValue(event.target.value)
  onValidationChange(event.target.checked) // Gültigkeit prüfen und Callback aufrufen
}
  const type = field.field_type

  return (
    <Form.Group>
      <StyledFormLabel htmlFor={field.field_name}>
        {field.field_name} {field.required === true ? ' *' : null}
      </StyledFormLabel>
      <Form.Check // prettier-ignore
        name={field.field_name} // Füge das name-Attribut hinzu
        type={type}
        id={field.field_name}
        required={field.required === true}
        onChange={handleChange} // onChange Prop hinzufügen
      />
    </Form.Group>
  )
}

export default CheckRadio
